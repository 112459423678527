import React from 'react';
import {
  Create,
  SimpleForm,
  ReferenceInput,
  Labeled,
  AutocompleteInput,
  SelectInput,
  TextInput,
  RadioButtonGroupInput,
  useGetOne,
  Title,
  FormDataConsumer,
  useNotify,
  useRedirect,
  usePermissions,
} from 'react-admin';
import BackButton from '../Components/BackButton';
import { useParams } from 'react-router-dom';
import { Typography, CircularProgress } from '@mui/material';
import TveTitle from '../Components/TveTitle';
import { withAuth } from '../Config/ApplicationDataProvider';
import axios from 'axios';
import { API_URL } from '../Config/ReactAppConfig';

const WarningMessage = () => (
  <span
    style={{ color: 'red', backgroundColor: 'white', textShadow: '4px 4px 8px rgba(0, 0, 0, 0.8)' }}
  >
    <p>
      <strong>
        <u>For accurate reporting purposes:</u>
      </strong>{' '}
    </p>
    <ul>
      <li style={{ maxWidth: '500px' }}>
        5-week Core + Strand course = select "Core + Strand" for both the SW3-based stacks for the
        first 2 weeks of class, AND the ensuing SW4-based stacks for the last 3 weeks of the class
        schedule.
      </li>
      <li style={{ maxWidth: '500px' }}>
        Replacement stacks = select the same Training Type as the original stack being replaced.{' '}
      </li>
    </ul>{' '}
  </span>
);

const getStudentInfo = async (studentId) => {
  const response = await axios(withAuth({ url: `${API_URL}/usersactive/${studentId}` }));
  return response.data !== null ? response.data.role.name : undefined;
};

const validateAppUserCreation = async (values) => {
  const errors = {};
  let selectedStudentType = undefined;
  if (!values.student) {
    errors.student = 'CAC Name selection is required';
  } else {
    selectedStudentType = await getStudentInfo(values.student);
  }
  // if (!values.studentName) {
  //   errors.studentName = 'Description is required';
  // } else
  if (values.studentName) {
    if (values.studentName.length > 255) {
      errors.studentName = 'Description is too long';
    }
  }
  if (!values.canesVersion) {
    errors.canesVersion = 'Stack Version is required';
  }
  if (!values.stackUserType) {
    errors.stackUserType = 'Stack User Type is required';
  }
  if (selectedStudentType === 'STUDENT') {
    if (values.stackUserType === 'Instructor') {
      errors.stackUserType = 'Cannot assign a Stack User Type of Instructor to a Student user';
    }
  }
  if (selectedStudentType === 'INSTRUCTOR') {
    if (values.stackUserType === 'Student') {
      errors.stackUserType = 'Cannot assign a Stack User Type of Student to an Instructor user';
    }
  }
  if (values.stackUserType === 'Student') {
    if (!values.trainingType) {
      errors.trainingType = 'Training Type is required';
    }
  }
  return errors;
};

const formDefaultValues = (classId) => {
  return {
    tveClass: { tveClassId: classId },
    ship: { shipId: 1 },
    tveStatus: { lookupCodeId: 5, name: 'Not Created' },
    appUserTrainingStatus: { lookupCodeId: 18 },
    tveStackId: '',
    tveStackName: '',
    guacUsername: '',
    guacUrl: '',
    guacProxyUrl: '',
    guacPassword: '',
    scheduleActive: 'N',
  };
};

const TveClassAppUserCreateTitle = () => {
  let { classId } = useParams();
  const baseTitle = 'Add Record to Class';
  const { data: tveclass, isLoading } = useGetOne('tveclasses', { id: classId });
  if (isLoading) {
    return <CircularProgress />;
  }
  return (
    <span>
      {baseTitle}: {tveclass.name}
    </span>
  );
};

const CACLabel = () => <div style={{ width: 250 }}>CAC Name (type to search) *</div>;

const ClassName = () => {
  const { classId } = useParams();
  const { data: tveclass, isLoading, error } = useGetOne('tveclasses', { id: classId });
  if (isLoading) {
    return <CircularProgress />;
  }
  if (error) {
    return <p>ERROR</p>;
  }
  return <div>{tveclass.name}</div>;
};

const Track = () => {
  const { classId } = useParams();
  const { data: tveclass, isLoading, error } = useGetOne('tveclasses', { id: classId });
  if (isLoading) {
    return <CircularProgress />;
  }
  if (error) {
    return <p>ERROR</p>;
  }
  return <div>{tveclass.track}</div>;
};

const translateClassType = (classtype) => {
  if (classtype === 'I') return 'Instructing';
  if (classtype === 'N') return 'Non-Instructing';
};

const ClassType = () => {
  const { classId } = useParams();
  const { data: tveclass, isLoading, error } = useGetOne('tveclasses', { id: classId });
  if (isLoading) {
    return <CircularProgress />;
  }
  if (error) {
    return <p>ERROR</p>;
  }
  return translateClassType(tveclass.classType);
};

const TveClassAppUserCreate = () => {
  const { classId } = useParams();
  const { data: tveClass } = useGetOne('tveclasses', { id: classId });
  const notify = useNotify();
  const redirect = useRedirect();
  const { permissions } = usePermissions();

  let today = new Date().toISOString().split('T')[0];

  const onSuccess = (data) => {
    notify(data.stackUserType + ` added`);
    redirect(`/tveclasses/${classId}/students`);
  };

  return (
    <>
      <BackButton label="Return to Class" to={`/tveclasses/${classId}/students`} />
      {tveClass?.endDate <= today ? (
        <>
          <Title title={<TveTitle pageName={<TveClassAppUserCreateTitle />} />} />
          <Typography variant="h7" sx={{ pt: 2 }}>
            <b>
              Class end date is expired. Please change the class end date before creating a new
              student session.
            </b>
          </Typography>
        </>
      ) : (
        <Create
          title={<TveTitle pageName={<TveClassAppUserCreateTitle />} />}
          resource="tveclassappusers"
          mutationOptions={{ onSuccess }}
        >
          <SimpleForm
            defaultValues={formDefaultValues(classId)}
            mode="onBlur"
            reValidateMode="onBlur"
            validate={validateAppUserCreation}
          >
            <Labeled label="Class" style={{ marginBottom: '10px' }}>
              <ClassName />
            </Labeled>

            <Labeled label="Class Track" style={{ marginBottom: '10px' }}>
              <Track />
            </Labeled>

            <Labeled label="Class Type" style={{ marginBottom: '10px' }}>
              <ClassType />
            </Labeled>

            <ReferenceInput
              source="student"
              reference="usersactive"
              sort={{ field: 'username', order: 'ASC' }}
            >
              <AutocompleteInput
                label={CACLabel()}
                optionText={(choice) => `${choice.username} (${choice.role.name})`}
                sx={{ width: 700 }}
              />
            </ReferenceInput>

            <TextInput label="Description (optional)" source="studentName" sx={{ width: 700 }} />

            {/* show only canesversions of the class's same track */}

            <ReferenceInput
              source="canesVersion"
              reference="canesversionsactivesonly"
              sort={{ field: 'name', order: 'ASC' }}
              fullWidth
              filter={{ track: tveClass.track }}
            >
              <SelectInput label="Stack Version" optionText="name" />
            </ReferenceInput>

            <RadioButtonGroupInput
              source="stackUserType"
              label="Stack User Type"
              choices={[
                { id: 'Instructor', name: 'Instructor' },
                { id: 'Student', name: 'Student' },
              ]}
              defaultValue="Student"
            />

            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.stackUserType !== 'Instructor' && (
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '330px', marginTop: '10px' }}>
                      <RadioButtonGroupInput
                        source="trainingType"
                        label="Training Type"
                        choices={[
                          { id: 'CS', name: 'Core + Strand' },
                          { id: 'S', name: 'Strand Only' },
                          { id: 'C', name: 'Core Only' },
                        ]}
                      />
                    </div>
                    <div style={{ marginTop: '10px' }}>
                      <WarningMessage />
                    </div>
                  </div>
                )
              }
            </FormDataConsumer>
          </SimpleForm>
        </Create>
      )}
    </>
  );
};

export default TveClassAppUserCreate;
