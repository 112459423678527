import React from 'react';
import {
  Edit,
  TabbedForm,
  ReferenceInput,
  Labeled,
  TextField,
  SelectField,
  TextInput,
  UrlField,
  SelectInput,
  DeleteWithConfirmButton,
  SaveButton,
  Toolbar,
  required,
  maxLength,
  useRecordContext,
  RadioButtonGroupInput,
  FunctionField,
  useGetOne,
  ReferenceField,
} from 'react-admin';
import BackButton from '../Components/BackButton';
import { canModifyStudent, canDeleteStudent } from '../Config/TveActions';
import GuacamoleInfo from '../Components/GuacamoleInfo';
import { useParams } from 'react-router-dom';

const WarningMessage = () => (
  <span
    style={{ color: 'red', backgroundColor: 'white', textShadow: '4px 4px 8px rgba(0, 0, 0, 0.8)' }}
  >
    <p>
      <strong>
        <u>For accurate reporting purposes:</u>
      </strong>{' '}
    </p>
    <ul>
      <li style={{ maxWidth: '500px' }}>
        5-week Core + Strand course = select "Core + Strand" for both the SW3-based stacks for the
        first 2 weeks of class, AND the ensuing SW4-based stacks for the last 3 weeks of the class
        schedule.
      </li>
      <li style={{ maxWidth: '500px' }}>
        Replacement stacks = select the same Training Type as the original stack being replaced.{' '}
      </li>
    </ul>{' '}
  </span>
);

const GuacInfo = () => {
  const record = useRecordContext();
  return <GuacamoleInfo record={record} />;
};

const CustomToolbar = (props) => {
  let { classId } = useParams();
  const record = useRecordContext();

  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <SaveButton />
      <DeleteWithConfirmButton
        confirmContent={`Are you sure you want to delete record #${record.tveClassAppUserId} from ${record.tveClass.name}`}
        redirect={`/tveclasses/${classId}/students`}
        disabled={!canDeleteStudent(record)}
        variant="contained"
        sx={{
          color: 'white',
          backgroundColor: 'red',
          padding: '6px 11px',
          '&:hover': {
            backgroundColor: '#0066CC',
          },
        }}
      />
    </Toolbar>
  );
};

const EditStudentTitle = () => {
  const record = useRecordContext();
  return (
    <span>
      {record
        ? `Edit Record: ${record.id}${record.student?.appUser?.appUserAlias
          ? ` - ${record.student?.appUser?.appUserAlias}`
          : ''
        }`
        : 'Edit'}
    </span>
  );
};

const CACLabel = () => <div style={{ width: 250 }}>CAC Name</div>;

const requiredField =
  (message = 'Required') =>
    (value) =>
      value ? undefined : message;

const translateClassType = (classtype) => {
  if (classtype === 'I') return 'Instructing';
  if (classtype === 'N') return 'Non-Instructing';
};

const timestampToDate = (timestampstring) => {
  // note the date is already in UTC, so just adding the label here
  if (timestampstring === null) {
    return null;
  }

  return `${timestampstring} (UTC)`;
};

const timestampToDateLocal = (timestampstring, official) => {
  if (timestampstring === null) {
    return null;
  }

  const dateLocal = new Date(timestampstring);
  const dateInClassTz = new Date(dateLocal.toLocaleString('en-US', { timeZone: official }));
  const year = dateInClassTz.getFullYear();
  const month = String(dateInClassTz.getMonth() + 1).padStart(2, '0');
  const day = String(dateInClassTz.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  const dateString =
    formattedDate + ' ' + dateInClassTz.toTimeString().split(' ')[0] + ' (' + official + ')';

  return `${dateString}`;
};

const timestampToDateLocalDropDate = (timestampstring, official) => {
  if (timestampstring === null) {
    return null;
  }

  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const dateLocal = new Date(timestampstring);
  const dateInClassTz = new Date(dateLocal.toLocaleString('en-US', { timeZone: official }));

  // Check if the day of the week is Thu or Fri
  const isThursdayOrFriday = dateInClassTz.getDay() === 4 || dateInClassTz.getDay() === 5;
  // Check if the day of the week is Sat or Sun
  const isWeekend = dateInClassTz.getDay() === 6 || dateInClassTz.getDay() === 0;

  let newDate;

  if (isThursdayOrFriday) {
    // set to 96 hours later (48 + additional 48 for the weekend hours)
    newDate = new Date(dateInClassTz.getTime() + 96 * 60 * 60 * 1000);
  } else if (isWeekend) {
    // set to 48 hours after the upcoming Mon at midnight

    // Get the current day of the week (0 for Sunday, 1 for Monday, etc.)
    const currentDay = dateInClassTz.getDay();
    // console.log(currentDay);

    // Calculate the difference between the current day and Monday
    const daysUntilNextMonday = currentDay === 1 ? 7 : (1 + 7 - currentDay) % 7;

    // Create a new Date object for the next Monday at midnight
    const nextMonday = new Date(dateInClassTz);
    nextMonday.setDate(dateInClassTz.getDate() + daysUntilNextMonday + 2);
    nextMonday.setHours(0, 0, 0, 0);
    newDate = nextMonday;
  } else {
    // set to 48 hours later
    newDate = new Date(dateInClassTz.getTime() + 48 * 60 * 60 * 1000);
  }

  const year = newDate.getFullYear();
  const month = String(newDate.getMonth() + 1).padStart(2, '0');
  const day = String(newDate.getDate()).padStart(2, '0');
  const hours = String(newDate.getHours()).padStart(2, '0');
  const minutes = String(newDate.getMinutes()).padStart(2, '0');
  const seconds = String(newDate.getSeconds()).padStart(2, '0');
  const dayOfWeek = daysOfWeek[newDate.getDay()];

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000+00:00 (${official}), ${dayOfWeek}`;
};

const StudentName = () => {
  const record = useRecordContext();

  if (record.tveStatus.name === 'Not Created') {
    return (
      <TextInput
        label="Description"
        source="studentName"
        validate={[maxLength(255)]}
        sx={{ width: 700 }}
      />
    );
  } else {
    return (
      <Labeled label="Student Name">
        <TextField sx={{ pb: 1.5 }} source="studentName" />
      </Labeled>
    );
  }
};

const CanesVersion = () => {
  const record = useRecordContext();

  {
    /* show only canesversions of the class's same track */
  }

  if (record.tveStatus.name === 'Not Created') {
    return (
      <ReferenceInput
        source="canesVersion.canesVersionId"
        resource="canesversionsactivesonly"
        reference="canesversionsactivesonly"
        sort={{ field: 'name', order: 'ASC' }}
        fullWidth
        filter={{ track: record?.tveClass?.track }}
      >
        <SelectInput
          optionText="name"
          label="Stack Version"
          validate={required()}
          sx={{ width: 700 }}
        />
      </ReferenceInput>
    );
  } else {
    return (
      <Labeled label="Stack Version">
        <TextField sx={{ pb: 1.5 }} source="canesVersion.name" />
      </Labeled>
    );
  }
};

const TrainingType = () => {
  const record = useRecordContext();

  if (record.stackUserType !== 'Instructor')
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ width: '330px', marginTop: '10px' }}>
          <RadioButtonGroupInput
            label="Training Type"
            source="trainingType"
            validate={required()}
            choices={[
              { id: 'CS', name: 'Core + Strand' },
              { id: 'S', name: 'Strand Only' },
              { id: 'C', name: 'Core Only' },
            ]}
          />
        </div>
        <div style={{ marginTop: '10px' }}>
          <WarningMessage />
        </div>
      </div>
    );
};

const TveClassAppUserEdit = () => {
  let { classId } = useParams();
  let { studentId } = useParams();
  const { data: tveClass } = useGetOne('tveclasses', { id: classId });

  return (
    <>
      <BackButton label="Return to Class" to={`/tveclasses/${classId}/students`} />
      <Edit
        title={<EditStudentTitle />}
        resource="tveclassappusers"
        id={studentId}
        redirect={`/tveclasses/${classId}/students`}
        mutationMode="pessimistic"
      >
        <TabbedForm toolbar={<CustomToolbar />} mode="onBlur" reValidateMode="onBlur">
          <TabbedForm.Tab label="Basic">
            <Labeled label="Class">
              <TextField sx={{ pb: 1.5 }} source="tveClass.name" />
            </Labeled>
            <Labeled label="Track">
              <TextField sx={{ pb: 1.5 }} source="tveClass.track" />
            </Labeled>
            <Labeled label="Class Type">
              <FunctionField
                label="Class Type"
                render={(record) => translateClassType(record.tveClass.classType)}
              />
            </Labeled>
            <ReferenceField
              source="student.id"
              reference="users"
              link={(record, reference) => `/${reference}/${record.id}`}
            >
              <Labeled label="CAC Name">
                <TextField source="username" label="asdf" />
              </Labeled>
            </ReferenceField>
            <StudentName />
            <CanesVersion />
            <Labeled label="Stack User Type">
              <SelectField
                source="stackUserType"
                choices={[
                  { id: 'Instructor', name: 'Instructor' },
                  { id: 'Student', name: 'Student' },
                ]}
              />
            </Labeled>
            <TrainingType />
            <GuacInfo />
          </TabbedForm.Tab>

          <TabbedForm.Tab label="Advanced">
            <Labeled label="Tve Stack Name" sx={{ pt: 1 }}>
              <TextField source="tveStackName" />
            </Labeled>
            <Labeled label="Tve Stack Id" sx={{ pt: 1 }}>
              <TextField source="tveStackId" />
            </Labeled>
            <Labeled label="Stack Commit Hash" sx={{ pt: 1 }}>
              <TextField source="stackCommitHash" />
            </Labeled>
            <Labeled label="Created Time" sx={{ pt: 1 }}>
              <FunctionField render={(record) => timestampToDate(record.createdTime)} />
            </Labeled>
            <FunctionField
              render={(record) =>
                timestampToDateLocal(record.createdTime, record.tveClass?.tveTimeZone?.official)
              }
            />
            <Labeled
              label="Auto Drop Time (when stack will automatically be dropped and deleted if still in 'Not Started' status)"
              sx={{ pt: 1 }}
            >
              <FunctionField
                render={(record) =>
                  timestampToDateLocalDropDate(
                    record.createdTime,
                    record.tveClass?.tveTimeZone?.official
                  )
                }
              />
            </Labeled>
            <Labeled label="Stack User Type" sx={{ pt: 1 }}>
              <TextField source="stackUserType" />
            </Labeled>
            <Labeled label="Guacamole URL" sx={{ pt: 1 }}>
              <UrlField source="guacUrl" target="_blank" />
            </Labeled>
            <Labeled label="Guacamole Proxy URL" sx={{ pt: 1 }}>
              <UrlField source="guacProxyUrl" target="_blank" />
            </Labeled>
            <Labeled label="Training Username" sx={{ pt: 1 }}>
              <TextField source="guacUsername" />
            </Labeled>
            <Labeled label="Training Password" sx={{ pt: 1 }}>
              <TextField source="guacPassword" />
            </Labeled>
          </TabbedForm.Tab>
        </TabbedForm>
      </Edit>
    </>
  );
};

export default TveClassAppUserEdit;
